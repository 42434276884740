<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-pills"> </i> Riesgo Bipsicosocial trimestre: {{trimestre}}</span> </div>
            <div class="card-body">
            <div v-for="item in preguntas" :key="item.id">
                <h6>{{item.nombre}}</h6>
                    <table class="table table-striped table-bordered table-sm" >
                    <tr>
                        <th>Pregunta</th>
                        <th>Respuesta</th>
                    </tr>
                    <tr v-for="pre in item.pregunta" :key="pre.id">
                        <td>{{pre.texto}}</td>
                        <td>
                        {{pre.respuesta}}
                        </td>
                    </tr>
                    
                </table>
            </div>
            <table class="table table-bordered">
                <tr>
                        <td class="font-weight-bolder">
                            <div class="d-flex justify-content-end">
                                Puntuación: {{ puntaje_total }}
                            </div>
                        </td>
                        <td class="font-weight-bolder text-center" :class="clasificacion.class">
                            {{clasificacion.text}}
                        </td>
                    </tr>
            </table>
        </div>
            </div>
    </main>
</template>
<script>
import usuarioService from '../../../../../services/usuarioService';
import tipoRiesgoBiopsicosocialService from '../../../../../services/tipoRiesgoBiopsicosocialService';
import valoracionRiesgoBiopsicosocialService from "../../../../../services/valoracionRiesgoBiopsicosocialService";
import {isEmpty} from "lodash";

export default {
    props: ['idHistoria','finalizada','idUsuario'],
    data() {
        return {
            usuario: {},
            preguntas: [],
            puntaje_total:'',
            trimestre:''
        };
    },
    methods: {
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;
        },
        async cargarPreguntas(){

            const res_valoracion = await valoracionRiesgoBiopsicosocialService.showByIdHc(
                this.idHistoria
            );
            if(!isEmpty(res_valoracion.data)) {
                this.trimestre=res_valoracion.data.trimestre;
                this.puntaje_total=res_valoracion.data.puntaje_total;
            }

            const response = await tipoRiesgoBiopsicosocialService.index();
            this.preguntas = response.data.map(p => {
               
                return {
                    ...p,
                    pregunta : p.pregunta.map(x => {
                         let respuesta='';
                        if(!isEmpty(res_valoracion.data)) {
                            const detalle = res_valoracion.data.valoracion_detalles.find((v) => v.id_pregunta === x.id);
                            
                            if (detalle) {
                                const opcion =x.opciones.find((y) => parseInt(y.valor) === parseInt(detalle.valor));
                                respuesta = opcion ? opcion.texto : '';
                            }
                            //respuesta=res_valoracion.data.valoracion_detalles.find(res=>parseInt(res.id_pregunta)===parseInt(x.id))?.valor || 0
                        }
                            return {
                                ...x,
                                respuesta : respuesta
                            }
                        
                    })
                }
            });
        },
      
    },
    async created() {
        await this.cargarUsuario(this.idUsuario);
        this.cargarPreguntas();
    },
    computed : {
        clasificacion(){

            const clasifications = {
                'bajo' : {
                    code : 'bajo',
                    text  : 'Bajo riesgo biopsicosocial',
                    class : 'table-success'
                },
                'alto' : {
                    code : 'alto',
                    text  : 'Alto riesgo biopsicosocial',
                    class : 'table-danger'
                }
            };

            return this.puntaje_total > 3 ? clasifications['alto'] : clasifications['bajo'];

        }
    }
}
</script>